import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Context } from '..';

const WsAPI = observer(() => {
    const { webSocketStore } = useContext(Context);
    console.log('Инициализация сокетов1')
    useEffect(() => {
        console.log('Инициализация сокетов2')
        const socket = new WebSocket('wss://scraplands.ru:4083');

        socket.onopen = () => {
            console.log('Соединение установлено');
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.status) {
                webSocketStore.setStatus(data.status); // Обновляем статус в хранилище
            }
        };

        socket.onclose = () => {
            console.log('Соединение закрыто');
        };

        socket.onerror = (error) => {
            console.error('Ошибка WebSocket', error);
        };

        // Закрытие соединения при размонтировании компонента
        return () => {
            socket.close();
        };
    }, [webSocketStore]);

    return null; // Компонент не выводит ничего на экран
});

export default WsAPI;